import React, {useState} from 'react'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';

import { TextField, Button} from '@mui/material';

import './Login.css'

const Login = ()=>{
     
     const [isSending, setIsSending] = useState(false);
     const [data, setData] = useState({
          email: '',
          password: '',
     });

     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };
     const admin = document.cookie.split("=")[0];
     if(admin === 'authx'){
          document.location = '/admin-dashboard';
     }

     const onChange = (e)=>{
          setData({
               ...data,
               [e.target.name]: e.target.value,
          });
     }

     const validate = () => {
          if(!data.email && data.email.indexOf("@") == -1){
               Toast.error("Please enter valid email");
               return false;
          }
          else if(!data.password){
               Toast.error("Please enter password");
               return false;
          }
          return true;
     }

     const onSubmit = async ()=>{
          setIsSending(true);
          if(!validate()){
               setIsSending(false);
               return;
          }
          try {
               const res = await axiosInstance.post("admin/login", {
                    password: data.password,
                    email: data.email
               });
               let date = new Date(Date.now() + ((((1000 * 60) * 60) * 24) * 30));
               date = date.toUTCString();
               document.cookie = "authx="+ res.data.auth + "; expires=" + date;
               setIsSending(false);
               if(res.data.isAdmin){
                    navigate('/admin-dashboard')
               }
          } catch (error) {
               console.log(error);
               setIsSending(false);
               Toast.error('Invalid email or password');
          }
     }

     return(
          <div className="d-flex justify-content-center align-items-center" style={{height:'100vh'}}>
               <div className='login_container d-flex align-items-center flex-column'>
                    <h2 className='text-center' style={{marginTop: '50px'}}>Admin</h2>
                    <TextField name='email' value={data.email} onChange={onChange} style={{width: '90%', marginTop: '40px', color: 'red'}} fullWidth label="Email" type="email"/>
                    <TextField name='password'value={data.password} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="Password" type="password" />
                    <button onClick={onSubmit} style={{width: '90%', marginTop: '30px', cursor: isSending ? "progress" : 'pointer'}} className="__btn">Login</button>
               </div>
          </div>
     );
}

export default Login;