import React, {useState, useEffect} from 'react'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { IconButton } from '@mui/material';

const DropdownMenu = (props) => {

     const items = props.items||[];
     const [selected, setSelected] = useState(props.defaultValue ? props.defaultValue : items[0]);

     const handelClick = (popupState, value, i) => {
          setSelected(value)
          popupState.close();
          if(typeof props.onChange == 'function'){
               props.onChange(value, i);
          }
     }

     useEffect(()=> {
          setSelected(props.defaultValue ? props.defaultValue : items[0])
     }, []);

     return (
          <PopupState variant="popover" popupId="demo-popup-menu">
               {(popupState) => (
                    <>
                         <div className={'__dropdown_menu d-flex align-items-center justify-content-between ' + props.className} variant="contained" {...bindTrigger(popupState)}>
                              <span style={{width:  props.width||'120px', height: props.height||"auto"}}>{selected}</span> 
                              <IconButton><ArrowDropDown/></IconButton>
                         </div>
                         <Menu {...bindMenu(popupState)}>
                              {
                                   items.map((val, i)=>{
                                        return <MenuItem onClick={()=>handelClick(popupState, val, i)}>{val}</MenuItem>
                                   })
                              }
                         </Menu>
                    </>
               )}
          </PopupState>
     );
}

export default DropdownMenu;