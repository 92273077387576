import React, {useState, useEffect} from 'react'
import { useLocation} from 'react-router-dom'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';

const UserHistory = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };
     const location = useLocation();

     const searchParam = new URLSearchParams(location.search);
     const defaultType = searchParam.get("defaultType");
     const user_name = searchParam.get("userName");
     const user_id = searchParam.get("id");

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [errorMessage, setErrorMessage] = useState();
     const [status, setStatus] = useState(defaultType||"earning");
     const [searchQuery, setSearchQuery] = useState(null);
     var tableHead = [];
     const selectionItems = ["Earning", "Topup", "Withdrawal", "Gifting"];
     const selectionItemKeys = ["earning", "topup", "withdrawal", "gift"];

     if(status == 'earning'){
          tableHead = ["Amount", "Target", "Date"];
     }
     else if(status == 'topup'){
          tableHead = ["Diamond", "Date"];
     }
     else if(status == 'withdrawal'){
          tableHead = ["Amount", "Payment method", "Status", "Date"];
     }
     else if(status == 'gift'){
          tableHead = ["Diamond", "Gift", "Status", "Date"];
     }

     const onPageChange = async (e, pageIndex, mStatus) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * pageIndex);
               params.append("limit", 20);
               params.append("user_id", user_id);
               if(mStatus){
                    params.append("status", mStatus);
               }
               else params.append("status", status);
               const res = await axiosInstance.get('admin/user-historys', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
          }
     }

     useEffect(async ()=>{
          onPageChange(null, 0);
     }, []);

     return (
          <>
               <Header/>
               <div className="container d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton>
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>{user_name} (History)</h5>
                    </div>
                    <DropdownMenu defaultValue={selectionItems[selectionItemKeys.indexOf(status)]||undefined} items={selectionItems} onChange={(value, i)=> {
                              const status = selectionItemKeys[i];
                              setStatus(status);
                              onPageChange(null, 0, status);
                         }}/>
               </div>
               <div className="__table_root container">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item itemType={status} data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const toFirstLetterUpperCase = (str) => {
     if(str && str.length >= 2){
          return str.substring(0, 1).toUpperCase() + str.substring(1);
     }
     return str
}

const getStatusView = (status) => {
     // rejected, submited, enabled, desabled, no_action
     var text = "";
     var className = "";
     if(status == "no_action"){
          text = "No action";
          className = "status_c_yellow";
     }
     else if(status == "submited"){
          text = "Submited";
          className = "status_c_blue";
     }
     else if(status == "desabled"){
          text = "Desabled";
          className = "status_c_red";
     }
     else if(status == "rejected"){
          text = "Rejected";
          className = "status_c_red";
     }
     else if(status == "enabled"){
          text = "Enabled";
          className = "status_c_green";
     }
     else {
          className = "status_c_yellow";
          text = "None";
     }

     return <span className={className + " item_status_view"}>{text}</span>
}

const Item = (props) => {

     console.log(props);
     if(props.itemType == 'earning'){
          return <EarningItem data={props.data} position={props.position}/>
     }
     else if(props.itemType == 'topup'){
          return <TopupItem data={props.data} position={props.position}/>
     }
     else if(props.itemType == 'withdrawal'){
          return <WithdrawalItem data={props.data} position={props.position}/>
     }
     else if(props.itemType == 'gift'){
          return <GiftingItem data={props.data} position={props.position}/>
     }
     return null;
}

const EarningItem = (props) => {
     const { whatsTarget, amount, currency, date } = props.data;

     return(
          <tr>
               <td>{`${currency == 'usd' ? "$" : "₹"}${amount}`}</td>
               <td>{whatsTarget}</td>
               <td>{new Date(date).toDateString()}</td>
          </tr>
     );
}

const TopupItem = (props) => {
     const { diamond, date } = props.data;

     return(
          <tr>
               <td style={{color: 'rgb(236, 205, 64)', fontWeight: 'bold'}}>{diamond}</td>
               <td>{new Date(date).toDateString()}</td>
          </tr>
     );
}

const WithdrawalItem = (props) => {
     const { paymentMethod, amount, status, currency, date } = props.data;

     return(
          <tr>
               <td>{`${currency == 'usd' ? "$" : "₹"}${amount}`}</td>
               <td>{String(paymentMethod||"").toUpperCase()}</td>
               <td>{toFirstLetterUpperCase(status)}</td>
               <td>{new Date(date).toDateString()}</td>
          </tr>
     );
}

const GiftingItem = (props) => {
     const { isReceived, diamond, giftName, giftUrl, date } = props.data;

     return(
          <tr>
               <td style={{color: 'rgb(236, 205, 64)', fontWeight: 'bold'}}>{diamond}</td>
               <td><img src={giftUrl} style={{width: '50px', height: '50px'}} /> {giftName}</td>
               <td style={{color: 'green', fontWeight: 'bold'}}>{isReceived ? "Received" : "Send"}</td>
               <td>{new Date(date).toDateString()}</td>
          </tr>
     );
}

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default UserHistory;