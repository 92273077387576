import React, {useState, useEffect} from 'react'
import NavLink from '../nav_link/NavLink';
import {useLocation} from 'react-router-dom'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded';
import AddRounded from '@mui/icons-material/AddRounded';
import { TextField, Button, Fab, IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AgentFormFullPage = () => {

     const location = useLocation();
     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const id = new URLSearchParams(location.search).get("id");

     const [data, setData] = useState({
          userId: "",
          fullName: "",
          contactNumber: "",
          rejectionMessage: "",
          country: "",
          state: "",
          address: "",
          emailId: "",
          govtDocumentFront: "",
          govtDocumentBack: "",
          status: '',
          date: ""
     });
     const [open, setOpen] = React.useState(false);
     const [errorMessage, setErrorMessage] = useState(null);
     const [isSending, setIsSending] = useState(null);
     const tableHead = ["Category", "Subject", "Last message from", "Last message", "Date", ""];

     const fetch = async () => {
          try {
               const params = new URLSearchParams();
               params.append("id", id);

               const res = await axiosInstance.get('admin/agent-form', {
                    params: params
               });
               setData(res.data);
          } 
          catch (error) {
               setErrorMessage("Failed");
               console.log(error);
          }
     }

     const submit = async (status) => {
          try {
               const body = new FormData();
               body.append("id", id);
               body.append("status", status);
               body.append("rejectionMessage", data.rejectionMessage);

               const res = await axiosInstance.patch('admin/agent-form', body, {
                    headers: { "Content-Type": "multipart/form-data" }
               });
               Toast.success("Submit");
               naviagte(-1);
          } 
          catch (error) {
               setErrorMessage("Failed");
               console.log(error);
               setIsSending(false);
          }
     }

     useEffect(async ()=>{
          fetch();
     }, []);

     const handleClickOpen = () => {
          setOpen(true);
        };
      
        const handleClose = () => {
          setOpen(false);
        };

     return (
          <>
               <Header/>
               <div className="container d-flex justify-content-around">
                    <div className='subject-box d-flex flex-column' style={{width: '50%'}}>
                         <header className='m-header'>
                              <IconButton onClick={()=>naviagte(-1)} style={{marginRight: '10px'}}><ArrowBackRounded/></IconButton>
                              <h1>Agent form</h1>
                              <span style={{flex: 1}}></span>
                         </header>
                         <div style={{display: 'flex', margin: '10px 30px'}}>
                              <h6 style={{display: 'inline'}}>Full Name</h6>
                              <span style={{flex: 1}}></span>
                              {data.fullName}
                         </div>
                         <div style={{display: 'flex', margin: '10px 30px'}}>
                              <h6 style={{display: 'inline'}}>Email</h6>
                              <span style={{flex: 1}}></span>
                              {data.emailId}
                         </div>
                         <div style={{display: 'flex', margin: '10px 30px'}}>
                              <h6 style={{display: 'inline'}}>Contact number</h6>
                              <span style={{flex: 1}}></span>
                              {data.contactNumber}
                         </div>
                         <div style={{display: 'flex', margin: '10px 30px'}}>
                              <h6 style={{display: 'inline'}}>Country</h6>
                              <span style={{flex: 1}}></span>
                              {data.country}
                         </div>
                         <div style={{display: 'flex', margin: '10px 30px'}}>
                              <h6 style={{display: 'inline'}}>State</h6>
                              <span style={{flex: 1}}></span>
                              {data.state}
                         </div>
                         <div style={{display: 'flex', margin: '10px 30px'}}>
                              <h6 style={{display: 'inline'}}>Address</h6>
                              <span style={{flex: 1}}></span>
                              {data.address}
                         </div>
                         <div style={{display: 'flex', margin: '10px 30px'}}>
                              <h6 style={{display: 'inline'}}>Date</h6>
                              <span style={{flex: 1}}></span>
                              {data.date}
                         </div>
                         <div style={{margin: '10px 30px'}}>
                              <h6>Government documents</h6>
                              <div className='d-flex justify-content-around' style={{maxHeight: '300px'}}>
                                   <img style={{width: '49%'}} src={data.govtDocumentFront}/>
                                   <img style={{width: '49%'}} src={data.govtDocumentBack}/>
                              </div>
                         </div>
                         <span style={{flex: 1}}></span>
                         <div className='d-flex justify-content-around flex-column'>
                              <button className='chat-actions' style={{margin: '10px 20px 10px 20px', width: '93%'}} onClick={()=> naviagte(`/admin-user-profile?id=${data.userId}&userName=${data.fullName}`)}>View user profile</button>
                              {data.status == "submited" ? <div className='d-flex justify-content-around'>
                                   <button className='chat-actions' style={{margin: '0 10px 10px 20px', backgroundColor: 'red' }} onClick={()=>setOpen(!open)} >Reject</button>
                                   <button className='chat-actions' style={{margin: '0 20px 10px 0', backgroundColor: 'green'}} onClick={()=> submit('enabled')} >Aprove</button>
                              </div> : null}
                         </div>
                    </div>
               </div>
               <Dialog open={open}>
                    <DialogTitle>Reject</DialogTitle>
                    <DialogContent>
                         <DialogContentText> Please write why you want to reject this form </DialogContentText>
                         <TextField
                              autoFocus
                              margin="dense"
                              id="name"
                              label=" Rejection message"
                              type="message"
                              fullWidth
                              variant="standard"
                              multiline 
                              value={data.rejectionMessage}
                              onChange={(e)=>setData({...data, rejectionMessage: e.target.value})}
                              />
                    </DialogContent>
                    <DialogActions>
                         <Button>Cancel</Button>
                         <Button onClick={()=>submit('rejected')}>Reject</Button>
                    </DialogActions>
               </Dialog>
          </>
     );
}


export default AgentFormFullPage;