import React, {useState} from 'react'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';

import { TextField, Button, Fab, IconButton } from '@mui/material';
import AddRounded from '@mui/icons-material/AddRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';

import Header from './../header/Header';

import './Notification.css';

const Notification = ()=>{

     const [isSending, setIsSending] = useState(false);
     const [data, setData] = useState({
          title: '',
          body: '',
          link: '',
          file: '',
     })

     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const onChange = (event)=>{
          const { value, name } = event.target;
          setData(pVal => {
               if(name != 'file'){
                    return {
                         ...pVal,
                         [name]: value
                    };
               }
               else{
                    return {
                         ...pVal,
                         file: event.target.files[0]
                    };
               }
          });
          console.log(data);
     }

     const validate = () => {
          if(!data.email && data.email.indexOf("@") == -1){
               Toast.error("Please enter valid email");
               return false;
          }
          else if(!data.password){
               Toast.error("Please enter password");
               return false;
          }
          return true;
     }

     const onSubmit = async ()=>{


          if(!data.title){
               Toast.error('Please enter title');
               return;
          }
          else if(!data.body){
               Toast.error('Please enter message');
               return;
          }

          setIsSending(true)
          try {
               const rBody = new FormData();
               rBody.append('title', data.title)
               rBody.append('message', data.body)
               rBody.append('link', data.link)
               rBody.append('file', data.file)
               const res = await axiosInstance.post("admin/push-notification", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               )
               setData({
                    title: '',
                    body: '',
                    link: '',
                    file: '',
               })
               Toast.success('Notification has been sent');
               setIsSending(false)
          } catch (error) {
               console.log(error);
               setIsSending(false)
               Toast.error('Failed to send notification')
               setData({
                    title: '',
                    body: '',
                    link: '',
                    file: '',
               })
          }
     }

     return(
          <>
               <Header/>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>navigate(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Notification</h5>
                    </div>
               </div>
               <div className="d-flex justify-content-center" style={{height:'100vh', marginTop: '20px'}}>
                    <div className='notification_container d-flex align-items-center flex-column'>
                         <h2 className='text-center' style={{marginTop: '50px'}}>Compose notification</h2>
                         <TextField name='title' value={data.title} onChange={onChange} style={{width: '90%', marginTop: '40px', color: 'red'}} fullWidth label="Title" type="text" inputProps={{ maxLength: 80 }}/>
                         <TextField name='link'value={data.link} onChange={onChange} style={{width: '90%', marginTop: '20px'}} fullWidth label="Link" type="url" />
                         <TextField name='body'value={data.body} onChange={onChange} style={{width: '90%', marginTop: '20px', }} fullWidth label="Message" type="text" multiline rows={4} inputProps={{ maxLength: 20 }}/>
                         
                         <div style={{ width: '100%', marginLeft: '35px'}}>
                              <div className='notification-import-image-root d-flex justify-content-center align-items-center' style={{marginTop: '20px'}}>
                                   { data.file ? <img style={{height: '100%', width: '100%'}} src={URL.createObjectURL(data.file)}/> : <div> <AddRounded /> Image </div> }
                              </div>
                              <label htmlFor="upload-photo" style={{marginTop: '10px'}}>
                                   <input name="file" onChange={onChange} style={{ display: 'none' }} id="upload-photo" type="file" itemType='image/*' />
                                   <Fab color="primary" size="small" component="span" aria-label="add" variant="extended">
                                        <AddRounded /> Upload
                                   </Fab>
                              </label>
                         </div>
                         <button onClick={onSubmit} style={{width: '90%', marginTop: '30px', cursor: isSending ? "progress" : 'pointer'}} className="__btn">Send</button>
                    </div>
               </div>
          </>
     );
}

export default Notification;