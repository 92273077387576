import React, {useState, useEffect} from 'react'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';

const HelpAndSupport = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [status, setStatus] = useState("new");
     const [errorMessage, setErrorMessage] = useState(null);
     const tableHead = ["Category", "Subject", "Last message from", "Last message", "Date", ""];
     const selectionItems = ["New data", "Old data"];
     const selectionItemKeys = ["new", "old"];

     const onPageChange = async (e, pageIndex, mStatus) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * (pageIndex-1));
               params.append("limit", 20);
               if(mStatus){
                    params.append("status", mStatus);
               }
               else params.append("status", status);

               const res = await axiosInstance.get('admin/help-and-support', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
               console.log(error);
          }
     }

     useEffect(async ()=>{
          onPageChange(null, 1);
     }, []);

     return (
          <>
               <Header/>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Help & Support</h5>
                    </div>
                    <DropdownMenu items={selectionItems} onChange={(value, i)=> {
                              const status = selectionItemKeys[i];
                              setStatus(status);
                              onPageChange(null, 1, status);
                         }}/>
               </div>
               <div className="__table_root">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {
     const { _id /** token id === _id */, subject, category, lastMessageFrom, lastContent, lastContentType, contactInfo, fileType, fileUrl, date } = props.data;

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     return(
          <tr>
               <td><span>{category.charAt(0).toUpperCase() + category.slice(1)}</span></td>
               <td><span>{subject.length <= 50 ? subject : subject.substring(0, 50) + "..."}</span></td>
               <td><span className={"item_status_view status_c_yellow"}>{lastMessageFrom}</span></td>
               <td><span>{lastContentType.charAt(0).toUpperCase() + lastContentType.slice(1).replace("_", " ").replace("_", " ")}</span></td>
               <td>{date}</td>
               <td><IconButton onClick={()=>naviagte(`/admin-help-and-support-chat?tokenId=${_id}&subject=${subject}&lastContent=${lastContent}&lastContentType=${lastContentType}&category=${category}&contactInfo=${contactInfo}&fileType=${fileType}&fileUrl=${fileUrl}`)}><ArrowForwardRounded/></IconButton></td>
          </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default HelpAndSupport;