import React, {useState, useEffect} from 'react'
import { useLocation} from 'react-router-dom'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';
import NavLink from '../nav_link/NavLink';

const AgentDiamondTransactions = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };
     const location = useLocation();

     const searchParam = new URLSearchParams(location.search);
     const agent_id = searchParam.get("id");

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [errorMessage, setErrorMessage] = useState();
     var tableHead = ["Receiver", "Diamonds", "Date"];

     const onPageChange = async (e, pageIndex, mStatus) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * (pageIndex-1));
               params.append("limit", 20);
               params.append("agent_id", agent_id);
               const res = await axiosInstance.get('admin/agent-diamond-transactions', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data);
          } 
          catch (error) {
               setErrorMessage("Failed");
          }
     }

     useEffect(async ()=>{
          onPageChange(null, 1);
     }, []);

     return (
          <>
               <Header/>
               <div className="container d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton>
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Transactions</h5>
                    </div>
               </div>
               <div className="__table_root container">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const toFirstLetterUpperCase = (str) => {
     if(str && str.length >= 2){
          return str.substring(0, 1).toUpperCase() + str.substring(1);
     }
     return str
}

const Item = (props) => {

     const { receiverId, receiverName, receiverProfilePhoto, diamond, date } = props.data;

     return(
          <tr>
               <td>
                    <img className='img_profile_photo' src={receiverProfilePhoto ? receiverProfilePhoto : "logo192.png"}/> 
                    <NavLink to={`/admin-user-profile?id=${receiverId}&userName=${receiverName}`}  className={isActive => "nav_link " + (isActive ? "" : "navlink_unselected")}>
                         {toFirstLetterUpperCase(receiverName)}
                    </NavLink>
               </td>
               <td style={{color: 'rgb(236, 205, 64)', fontWeight: 'bold'}}>{diamond}</td>
               <td>{new Date(date).toDateString()}</td>
          </tr>
     );
     
}

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default AgentDiamondTransactions;