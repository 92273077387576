import React, {useState, useEffect} from 'react'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';
import NavLink from '../nav_link/NavLink';

const HostForm = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [errorMessage, setErrorMessage] = useState(null);
     const [status, setStatus] = useState("submited");
     const [searchQuery, setSearchQuery] = useState(null);
     const tableHead = ["User name", "Email", "Country", "Address", "Date", ""];
     const selectionItems = ["New", "Rejected", "Enabled", "Desabled"];
     const selectionItemKeys = ["submited", "rejected", "enabled", "desabled"];

     const onPageChange = async (e, pageIndex, mStatus) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * (pageIndex-1));
               params.append("limit", 20);
               params.append("filterByNew", true);

               if(searchQuery){
                    params.append("searchQuery", searchQuery);
               }
               if(mStatus){
                    params.append("status", mStatus);
               }
               else params.append("status", status);
               const res = await axiosInstance.get('admin/host-forms', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
          }
     }

     useEffect(async ()=>{
          onPageChange(null, 1);
     }, []);

     return (
          <>
               <Header/>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Host form's</h5>
                    </div>
                    <DropdownMenu items={selectionItems} onChange={(value, i)=> {
                              const status = selectionItemKeys[i];
                              setStatus(status);
                              onPageChange(null, 1, status);
                         }}/>
               </div>
               <div className="__table_root">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {
     const { user_id, _id, userName, country, emailId, address, date } = props.data;

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const getStatusView = (status) => {
          // rejected, submited, enabled, desabled, no_action
          var text = "";
          var className = "";
          if(status == "no_action"){
               text = "No action";
               className = "status_c_yellow";
          }
          else if(status == "submited"){
               text = "Submited";
               className = "status_c_blue";
          }
          else if(status == "desabled"){
               text = "Desabled";
               className = "status_c_red";
          }
          else if(status == "rejected"){
               text = "Rejected";
               className = "status_c_red";
          }
          else if(status == "enabled"){
               text = "Enabled";
               className = "status_c_green";
          }
          else {
               className = "status_c_yellow";
               text = "None";
          }

          return <span className={className + " item_status_view"}>{text}</span>
     }

     return(
          <tr>
               <td>
                    <NavLink to={`/admin-user-profile?id=${user_id}&userName=${userName}`} a className={isActive => "nav_link " + (isActive ? "" : "navlink_unselected")}>
                         {userName}
                    </NavLink>
               </td>
               <td>{emailId}</td>
               <td>{country}</td>
               <td><span>{address.length <= 50 ? address : address.substring(0, 50) + "..."}</span></td>
               <td>{date}</td>
               <td><IconButton onClick={()=>naviagte(`/admin-host-form-full?id=${_id}`)}><ArrowForwardRounded/></IconButton></td>
          </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default HostForm;