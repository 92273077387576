import React from 'react'

const NavLink = (props) => {


     return (
          <>
               <a className='nav_link' href={props.to}>{props.children}</a>
          </>
     );
}

export default NavLink;